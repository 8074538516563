import * as React from 'react'
import { Link } from 'gatsby'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'

// Utils
import { repositoryConfigs } from './src/utils/prismicPreviews'
import { linkResolver } from './src/utils/linkResolver'

import './src/styles/default.css'

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    linkResolver={linkResolver}
    externalLinkComponent={(props) => {
      const childProps = { ...props }
      delete childProps.activeClassName
      childProps.target = props.target || '_blank'
      childProps.rel = props.rel || 'noopener noreferrer'
      return (
        <a data-location='external' {...props}>
          {props.children}
        </a>
      )
    }}
    internalLinkComponent={({ href, ...props }) => <Link to={href} {...props} />}
    repositoryConfigs={repositoryConfigs}>
    {element}
  </PrismicPreviewProvider>
)
